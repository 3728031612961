import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  state = {};
  render() {
    const { children } = this.props
    return (
      <>
        <nav
          class="navbar navbar-expand-lg navbar-light fixed-top"
          id="mainNav"
        >
          <div class="container"  >
            <Link class="navbar-brand" to="/">
              
              <img
                src="img/Web 1920 – 11@2x.png"
                alt="fltech"
              />
              <span style={{ fontSize:'45px' }}>Fluoresce Tech</span>
            </Link>
            <button
              class="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <i class="fas fa-bars" />
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <Link class="nav-link" to="/home" style={{fontSize:'18px'}}>
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/work" style={{fontSize:'18px'}}>
                    Work
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/aboutus" style={{fontSize:'18px'}}>
                    About Us
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/services" style={{fontSize:'18px'}}>
                    Services
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/contact" style={{fontSize:'18px'}}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {children}
      </>
    );
  }
}

export default Header;
