import React from 'react';
import './App.css';
import { Route } from "react-router-dom";
import AboutUS from "./components/aboutus";
import Home from "./components/home";
import Contact from "./components/contact";
import Services from "./components/services";
import Work from "./components/work";

const routes = [
  {
    path : "/aboutus",
    component: AboutUS
  },
  {
    path : "/",
    exact: true,
    component: Home
  },
  {
    path : "/home",
    component: Home
  },
  {
    path : "/contact",
    component: Contact
  },
  {
    path : "/services",
    component: Services
  },
  {
    path : "/work",
    component: Work
  }
]

function App() {
  return (
    <div className="App">
      {routes.map(item => 
        <Route path={item.path} exact={item.exact}  component={item.component} />
      )}  
    </div>
  );
}

export default App;
