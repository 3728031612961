import React, { Component } from "react";
import Header from "../header/index";
import Footer from "../footer/index";

class AboutUS extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header>
          <header
            class="masthead"
            style={{ backgroundImage: "url('img/about-bg.jpg')" }}
          >
            <div class="overlay" />
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <div class="page-heading">
                    <h2 ><b>Your Search for RIGHT TALENT</b></h2>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Header>

        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 mx-auto">
              <p>
                Fluoresce Tech to bring our industry-leading workforce solutions
                and career opportunities to every industry and across the U.S.
                and Canada.
              </p>
              <p>
                It’s a fast-moving, always-pivoting, reinvented world out there.
                We’re here to help. With the experience and expertise to plan
                and execute even the most complex business initiatives, we stand
                ready to work side-by-side with you. To activate your vision. To
                reach your goals. To transform your business.
              </p>

              <h4 style={{color: '#1DB7FC' }}><b>Join the Fluoresce Tech Family</b></h4>
              <p>
                We Love What We Do. We Love Who We Serve. Fluoresce Tech
                provides Great Results Through Strategic Partnership And
                Knowledge Sharing.
              </p>
              <p>
                Our clients and candidates are re-defining the marketplace and
                we’re giving them the skillets and opportunities to do so. We’re
                not just in the business of staffing, we’re in the business of
                making a difference.
              </p>
              <p>
                At Fluoresce Tech, we are always seeking great people and
                talented professionals to join our team. Whether you’re an
                experienced staffing specialist, a sales expert or someone
                looking for a career change, our company may be the right match
                for you.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <Footer />
      </div>
    );
  }
}

export default AboutUS;
