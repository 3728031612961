import React, { Component } from "react";
import Header from "../header/index";
import Footer from "../footer/index";

class Contact extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header>
          <header
            class="masthead"
            style={{ backgroundImage: "url('img/contact-bg.jpg')" }}
          >
            <div class="overlay" />
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <div class="page-heading">
                    <h2><b>Contact Me</b></h2>
                    <span class="subheading" style={{color:'black'}}>
                      <b>Have questions? I have answers.</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Header>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto">
              <p>
                Want to get in touch? Fill out the form below to send me a
                message and I will get back to you as soon as possible!
              </p>

              <form  action="https://mailthis.to/hr@fluorescetech.com" name="sentMessage" id="contactForm" novalidate method="post">
                <div class="control-group">
                  <div class="form-group floating-label-form-group controls">
                    <label>Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      id="name"
                      required
                      data-validation-required-message="Please enter your name."
                    />
                    <p class="help-block text-danger" />
                  </div>
                </div>
                <div class="control-group">
                  <div class="form-group floating-label-form-group controls">
                    <label>Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                      id="email"
                      required
                      data-validation-required-message="Please enter your email address."
                    />
                    <p class="help-block text-danger" />
                  </div>
                </div>
                <div class="control-group">
                  <div class="form-group col-xs-12 floating-label-form-group controls">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Phone Number"
                      id="phone"
                      required
                      data-validation-required-message="Please enter your phone number."
                    />
                    <p class="help-block text-danger" />
                  </div>
                </div>
                <div class="control-group">
                  <div class="form-group floating-label-form-group controls">
                    <label>Message</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Message"
                      id="message"
                      required
                      data-validation-required-message="Please enter a message."
                    />
                    <p class="help-block text-danger" />
                  </div>
                </div>
                <br />
                <div id="success" />
                <div class="form-group">
                  <input
                    type="submit"
                    class="btn btn-primary py-3 px-5 btn-block"
                    value="Send Message"
                    
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <hr />
        <Footer />
      </div>
    );
  }
}

export default Contact;
