import React, { Component } from "react";
import Header from "../header/index";
import Footer from "../footer/index";

class Work extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header>
          <header
            class="masthead"
            style={{ backgroundImage: "url('img/work-bg.jpg')" }}
          >
            <div class="overlay" />
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <div class="page-heading">
                    <h2><b>How we Bring Creativity to Life</b></h2>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Header>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 mx-auto">
              <h4 class="section-title" style={{ textAlign:'left' }}><b>Our Works</b></h4>
              <h5  style={{ textAlign:'left', color: '#1DB7FC' }}>
               <b>MORE POWERFUL THAN MARKET SPEAK</b> 
              </h5>
              <p>
                Basic tried-and-true practices are a great place to start. But
                at Fluoresce, we know you’re not looking to do the same thing as
                everyone else. Your transformation demands more than a
                one-size-fits-all solution. So we go deeper. We analyze your
                industry from the inside out and work with you to own your space
                within it.
              </p>

              <h4 style={{ textAlign:'left', color: '#1DB7FC' }}><b>Why Work at FLUORESCE</b></h4>
              <h5 style={{ textAlign:'left' }}><b>LOOKING FOR IT JOBS? LOOK TO FLUORESCE TECH!</b></h5>
              <h5 style={{ textAlign:'left' }}>
               <b>PERMANENT & CONTRACT ERP STAFFING & RECRUITING SPECIALISTS.</b> 
              </h5>
              <p>
                With over thirty years of experience in assisting IT consultants
                find the right career opportunity, Fluoresce offers the highest
                standard of services within the industry.
              </p>
              <p>“Our commitment to excellence is our greatest attribute”.</p>

              <p>Companies come to us for three reasons:</p>
              <p>1. To cut down on the number of unqualified applicants.</p>
              <p>
                2. Find more targeted candidates who meet the qualifications of
                the position
              </p>
              <p> 3. To engage and hire those candidate more quickly</p>

              <p>
                By partnering with Fluoresce, you stand a better chance of
                finding the perfect opportunity to advance your career.
              </p>
              <p>
                The most successful companies are always looking for the best
                and brightest talent available, and if you have the skills and
                experience that can provide value to a company, FLUORESCE has
                the connections to get you in front of those companies.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <Footer />
        
      </div>
    );
  }
}

export default Work;
