import React, { Component } from "react";
import Header from "../header/index";
import Footer from "../footer/index";

class Home extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header>
          <header
            class="masthead"
            style={{ backgroundImage: "url('img/home-bg.jpg')"}}
          >
            <div class="overlay" />
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <div class="page-heading">
                    <h3><b>LET'S GET TO NEXT.</b></h3>
                    <span class="subheading" 
                     style={{textAlign:'justify'}}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We come alongside, developing and training leaders and employees in the skills required to 
                      lead and manage effectively. Customized workshops, evaluation of key personnel,systems
                      and tools make inscape your ideal partner for leadership developments.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Header>
        <hr/>
        <Footer />
        
      </div>
    );
  }
}

export default Home;
