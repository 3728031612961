import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component {
    state = {  }
    render() {
        return (
            <footer>
            <div class="container">
              <div class="row" style={{color:"#171717"}}>
                <div class="col-md-4 ml-auto">
                  <h5 style={{ textAlign:'left' }}><b>Submit A Resume</b></h5>
                  <form action="https://mailthis.to/hr@fluorescetech.com" method="post" enctype="multipart/form-data">
                    <div style={{marginBottom: "10px"}}>
                      <input type="file"  id="file" name="file" multiple/>
                    </div>
                    <div>
                      <input type="submit" value="Submit Resume" style={{ float:'left' }}  />
                    </div> 
                   
                   </form>
                </div>
                <div class="col-md-4 ml-auto" >
                  <h5 style={{ textAlign:'center' }}><b>Find An Office</b></h5>
                  <ul class="list-unstyled footer-links">
                    <li>Fluoresce Technologies LLC</li>
                    <li>631 Perry Dr,</li>
                    <li>North Brunswick,</li>
                    <li>NJ 08902.</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <h5 style={{ textAlign:'left', fontSize:'18px' }}><b>Sign Up For Our Job & Career</b> </h5>
                  <form action="https://mailthis.to/hr@fluorescetech.com" method="post">
                    <div class="d-flex mb-5">                
                      <input type="text" class="form-control rounded-10" style={{width:'200px'}} placeholder="Email"/>
                      <input type="submit" value="Sign Me Up" />
                    </div>
                   </form>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <ul class="list-inline text-center">
                    <li class="list-inline-item">
                      <a href="https://twitter.com/fluorescetech/">
                        <span class="fa-stack fa-lg">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-twitter fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="https://www.facebook.com/Fluoresce-Technologies-LLC-2309494739165520/">
                        <span class="fa-stack fa-lg">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="https://www.linkedin.com/in/fluoresce-tech-9ba460188/">
                        <span class="fa-stack fa-lg">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-linkedin fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div class="col-lg-7 col-md-9 mx-auto">
                    <p  style={{  fontSize:'16px' }}>
                      Copyright &copy;
                      <script>document.write(new Date().getFullYear())FLUORESCE Technologies LLC;</script>
                      2019 FLUORESCE TECH All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        );
    }
}

export default Footer;