import React, { Component } from "react";
import Header from "../header/index";
import Footer from "../footer/index";

class Services extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header>
          <header
            class="masthead"
            style={{ backgroundImage: "url('img/post-sample-image.jpg')" }}
          >
            <div class="overlay" />
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                  <div class="post-heading">
                    <h5 class="subheading">
                      Our consulting services form the foundation for
                      long-lasting IT partnerships that help companies imagine
                      and innovate in the digital world.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Header>
        <article>
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 mx-auto">
                <h5  style={{ textAlign:'left' }}><b>STAFFING</b></h5>
                <p>
                  You need a partner with the knowledge and reach to provide the
                  best talent to meet your organizational goals. Our presence in
                  over 70 markets across the U.S. and Canada coupled with our
                  strong industry and skill focus enable us to address critical
                  gaps in the workforce.
                </p>

                <h5 style={{ textAlign:'left', color: "#1DB7FC" }}><b>What sets us apart:</b></h5>

                <p>
                  • We specialize in 20 unique skill areas within
                  Infrastructure, Application Development, Security, PMO
                  technical, Engineering, and Life Science disciplines.
                </p>
                <p>
                  • We have industry specialists to support technical and life
                  science needs for all major industries.
                </p>
                <p>
                  Ultimately, the heart of Fluoresce Tech is our people. Our
                  team is committed to the core purpose of helping organizations
                  thrive by connecting them to proven professionals who can
                  support their programs. It is the reason our employees display
                  unwavering dedication to providing the nation’s best staffing
                  and services.
                </p>

                <p>
                  As specialists in enlisting, we do substantially more than
                  basically place laborers — we give a variety of counseling
                  administrations and work with you to inspect your one of a
                  kind and explicit needs at that point actualize a customized
                  arrangement. Our broadness of administrations is coordinated
                  by our remarkable pledge to client administration. We join
                  forces with your association and become a consistent
                  augmentation of your HR staff. All the while, we help drive
                  down your all out expense of distinguishing and enrolling
                  ability while improving the nature of your workforce.
                </p>

                <p>
                  Fluoresce has been considering, creating, and improving
                  procedures to address the difficulties in vital staffing
                  administrations, merchant the executives, and subcontractor
                  connections. Because of our endeavors, we have worked with
                  numerous organizations to actualize arrangements that have
                  yielded noteworthy returns for our customers.
                </p>
              </div>
            </div>
          </div>
        </article>

        
        <hr />
        <Footer />
      </div>
    );
  }
}

export default Services;
